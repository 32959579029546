import React from "react";
import { graphql, HeadFC } from "gatsby";
import Layout from "../components/layout";
import { Trans } from "react-i18next";
import Panzerotto from "../templates/panzerotto";
import Heading from "../components/heading";
import { PromoWebshop } from "../components/promo";
import { HeaderSpacer, InternalHeaderSpacer } from "../components/header";
import SEO from "../seo";

const Profile = ({
  name,
  avatar,
  tagline,
}: {
  name: string;
  avatar: string;
  tagline: string;
}) => (
  <li className="flex flex-col w-screen max-w-lg overflow-hidden">
    <div
      className="p-2 w-full aspect-square max-w-screen-lg  flex flex-col justify-end"
      style={{ background: `center / cover url(${avatar})` }}
    >
      <h4 className="text-2xl text-red-500">
        <span className="bg-white/80 px-1">{name}</span>
      </h4>
    </div>
    <h6 className="px-2 bg-green-500 text-white w-full">
      <Trans>{tagline}</Trans>
    </h6>
  </li>
);
//
const About = () => {
  return (
    <Layout className="snap-proximity">
      <Panzerotto background="center / cover url(/about/story.jpg)">
        <HeaderSpacer />
        <div className="bg-yellow-300/100 max-w-screen-lg lg:mx-auto">
          <Heading>Our Mission</Heading>
          <p className="p-4">
            {/* prettier-ignore */}
            <Trans>Our mission is to revive traditional Apulian cuisine from Italy and be a vibrant addition to Rotterdam’s Market district. Fill your plate with the classic flavours of Southern Italy right here in Rotterdam! Our deep love of great food, good company, and Italian traditions is what Panzerò is all about!</Trans>
          </p>
        </div>
      </Panzerotto>
      <div className="p-4 snap-start max-w-screen-lg lg:mx-auto">
        <InternalHeaderSpacer />
        <h3 className="text-2xl text-red-500">Our Story</h3>
        <p>
          {/* prettier-ignore */}
          <Trans>The beginning of our Panzerò journey started with a phone call, two best friends, and a deep love for Apulian-style food. We knew our vision of reviving Apulian traditions in Rotterdam could become a reality when we started to test recipes on our friends and family, and they couldn’t get enough! They helped us build trust in our vision to do something different from the typical pizza, pasta, and Italian food already present in Rotterdam. Our dough is the heart and soul of Panzerò, and it took us six long months of experimentation before we perfected the recipe. Once we had our dough down to a science everything else just seemed to fall into place, and poof, Panzerò was born! We opened Panzerò in 2016 with simple and delicious food in mind, the way our grandmothers used to make it. We invite you to try our tasty panzerotti or fabulous Puccia, crafted by our talented culinary team just for you! We believe connecting over food is the best way to bring everyone together. So relax and enjoy the Panzerò experience knowing you will always eat well when you’re dining with us!</Trans>
        </p>
      </div>
      <ul className="flex flex-wrap max-w-screen-lg lg:mx-auto">
        {[
          {
            name: "Luigi Incalza",
            avatar: "/about/luigi_incalza.jpg",
            tagline: "Creative Culinarian - Innovative - Free Spirited",
          },
          {
            name: "Mimmo Vecchio",
            avatar: "/about/cosimo_vecchio.jpg",
            tagline: "Wise - Wine Conosseiur - Quality Controller",
          },
        ].map(Profile)}
      </ul>
      <PromoWebshop />
    </Layout>
  );
};

export default About;

export const Head: HeadFC = () => <SEO title="About us" />;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
