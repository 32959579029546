import React from "react";

type PanzerottoProps = React.HTMLProps<HTMLDivElement> & {
  background: string;
};

const Panzerotto = React.forwardRef<HTMLDivElement, PanzerottoProps>(
  (
    { className = "", background, children, as, ...props }: PanzerottoProps,
    ref
  ) => {
    return (
      <section
        ref={ref}
        style={{ background }}
        className={`${className} snap-start snap-always min-h-screen lg:border-b-8 first:border-none lg:border-white`}
        {...props}
      >
        {children}
      </section>
    );
  }
);

export default Panzerotto;
